<template>
  <v-dialog v-model="showDialog" persistent :max-width="maxWidth">
    <v-card>
      <v-row class="mr-0">
        <v-col cols="8" class="pr-0">
          <v-card-title
            class="font-weight-bold py-0 pr-0"
            style="font-size:15px"
          >
            {{ $t('label.downloadDocument') }}
          </v-card-title>
        </v-col>
        <v-col class="pl-0" cols="4">
          <v-btn
            rounded
            class="font-weight-black float-right"
            color="back"
            width="5vw"
            max-width="170px"
            style="font-size:15px !important"
            dark
            @click="onClickNegativeButton"
          >
            {{ $t('button.close') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row> </v-row>
      <v-row class="pl-5">
        <v-col class="pr-0">
          <p>{{ $t('label.applicationFormReceiptForCustomer') }}</p>
        </v-col>
        <v-col>
          <v-btn
            class="font-weight-black float-right mr-4"
            align="center"
            width="30vw"
            max-width="120px"
            color="next"
            style="font-size:15px"
            dark
            @click="onClickDownloadApplicationFormButton"
            rounded
            >{{ $t('button.downloadFile') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="pl-5">
        <v-col class="pr-0">
          <p>{{ $t('label.statementOfImportantMatters') }}</p>
        </v-col>
        <v-col>
          <v-btn
            class="font-weight-black float-right mr-4"
            align="center"
            width="30vw"
            max-width="120px"
            color="next"
            style="font-size:15px"
            dark
            @click="onClickDownloadStatementOfImportantMattersButton"
            rounded
            >{{ $t('button.downloadFile') }}</v-btn
          >
        </v-col>
      </v-row>
      <template v-if="internetCovenantImage">
        <v-row class="pl-5">
          <v-col class="pr-0">
            <p>{{ $t('label.internetCovenant') }}</p>
          </v-col>
          <v-col>
            <v-btn
              class="font-weight-black float-right mr-4"
              align="center"
              width="30vw"
              max-width="120px"
              color="next"
              style="font-size:15px"
              dark
              @click="onClickDonwloadInternetCovenantButton"
              rounded
              >{{ $t('button.downloadFile') }}</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  name: 'SelectDownloadDocumentDialog',
  props: {
    maxWidth: Number,
    onClickDownloadApplicationFormButton: Function,
    onClickDownloadStatementOfImportantMattersButton: Function,
    onClickDonwloadInternetCovenantButton: Function,
    onClickNegativeButton: Function,
    showDialog: Boolean,
    internetCovenantImage: Boolean,
  },
  data() {
    return {
      inset: false,
      textCenterStyle: ['font-weight-black', 'text-center', 'pt-5'],
      textLeftStyle: ['font-weight-black', 'text-left', 'pt-5'],
    };
  },
  methods: {
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
  },
};
</script>
