var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            {
              staticClass: "mx-auto",
              staticStyle: { width: "90%", "max-width": "450px" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-img", {
                        staticClass: "mx-auto",
                        attrs: {
                          src: require("@/assets/img/logo.gif"),
                          width: "100%",
                          "max-width": "200px"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isConcluded
                ? _c(
                    "div",
                    [
                      _c("v-row", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-weight-bold mx-auto pt-5 pb-0 mb-0",
                            staticStyle: { "font-size": "18px" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "title.contractor.noticeOfContractCompletion"
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm.isSkipped
                          ? _c(
                              "p",
                              {
                                staticClass: "pt-0 mx-auto",
                                staticStyle: { "font-size": "16px" },
                                attrs: { align: "center" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(this.subTitle) +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm.isSkipped
                        ? [
                            _c("v-row", [
                              _c("p", { staticClass: "pb-0 mb-0" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "description.procedureComplete.policyNumber",
                                        {
                                          policyNumber: _vm.policyNumber
                                        }
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]),
                            _c("v-row", [
                              _c("p", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "description.procedureComplete.differentPolicyNumberExplanation"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "pb-4" },
                              [
                                _c("v-row", [
                                  _c("p", { staticClass: "pb-0 mb-0" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "description.procedureComplete.customerName"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                ]),
                                _c("v-row", { staticClass: "py-0 my-0" }, [
                                  _c("p", { staticClass: "py-0 my-0" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.customerName1) +
                                        "\n              "
                                    )
                                  ])
                                ]),
                                _vm.customerName2
                                  ? _c("v-row", { staticClass: "py-0 my-0" }, [
                                      _c("p", { staticClass: "py-0 my-0" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.customerName2) +
                                            "\n              "
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.customerName3
                                  ? _c("v-row", { staticClass: "py-0 my-0" }, [
                                      _c("p", { staticClass: "py-0 my-0" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.customerName3) +
                                            "\n              "
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.isSkipped && _vm.isConcludedWithModify
                        ? _c(
                            "div",
                            [
                              _c("v-row", [
                                _c("p", { staticClass: "pb-0 mb-0" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "description.procedureComplete.subTitleForModification"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ])
                              ]),
                              _c("FixList", {
                                attrs: { fixContents: _vm.fixContents }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isSkipped &&
                      _vm.isConcluded &&
                      !_vm.isConcludedWithModify
                        ? [
                            _c("v-row", [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "description.procedureComplete.explanation1"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _c("v-row", [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "description.procedureComplete.explanation2"
                                    )
                                  )
                                )
                              ])
                            ])
                          ]
                        : _vm._e(),
                      !_vm.isSkipped && _vm.isConcludedWithModify
                        ? [
                            _c("v-row", [
                              _c("p", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "description.procedureComplete.explanation1WithModify"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]),
                            _c("v-row", [
                              _c("p", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "description.procedureComplete.explanation2WithModify"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ])
                          ]
                        : _vm._e(),
                      !_vm.isSkipped && _vm.isConcluded
                        ? [
                            _c("v-row", [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "description.procedureComplete.explanation3"
                                    )
                                  )
                                )
                              ])
                            ])
                          ]
                        : _vm._e(),
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "description.procedureComplete.explanation4"
                              )
                            )
                          )
                        ])
                      ]),
                      _vm.internetCovenantImage
                        ? [
                            _c("v-row", [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "description.procedureComplete.explanation5"
                                    )
                                  )
                                )
                              ])
                            ])
                          ]
                        : [
                            _c("v-row", [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "description.procedureComplete.explanation5-2"
                                    )
                                  )
                                )
                              ])
                            ])
                          ],
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "description.procedureComplete.explanation6"
                                )
                              )
                          ),
                          _vm.isConcludedWithModify
                            ? _c("span", { staticClass: "red--text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "description.procedureComplete.explanation6WithModifyNote"
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "description.procedureComplete.explanation7"
                              )
                            )
                          )
                        ])
                      ]),
                      _vm.internetCovenantImage
                        ? [
                            _c("v-row", [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "description.procedureComplete.explanation8"
                                    )
                                  )
                                )
                              ])
                            ])
                          ]
                        : _vm._e(),
                      _c("v-row", [
                        _c("p", { staticClass: "pt-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("description.procedureComplete.contact")
                              ) +
                              "\n          "
                          )
                        ])
                      ])
                    ],
                    2
                  )
                : _vm._e(),
              !_vm.isConcluded
                ? _c(
                    "div",
                    [
                      _vm.isWithdrawl
                        ? _c("v-row", [
                            _c(
                              "p",
                              {
                                staticClass: "font-weight-bold mx-auto pt-5",
                                staticStyle: { "font-size": "18px" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("title.contractor.noticeOfCancel")
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      !_vm.isWithdrawl
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-weight-bold mx-auto pt-5 pb-0 mb-0",
                                  staticStyle: { "font-size": "18px" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "title.contractor.noticeOfAcceptance"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm.isSkipped
                                ? [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "pt-0 mx-auto",
                                        staticStyle: { "font-size": "16px" },
                                        attrs: { align: "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(this.subTitle) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.isSkipped
                        ? [
                            _c("v-row", [
                              _c("p", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "description.procedureComplete.policyNumber",
                                        {
                                          policyNumber: _vm.policyNumber
                                        }
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "pb-4" },
                              [
                                _c("v-row", [
                                  _c("p", { staticClass: "pb-0 mb-0" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "description.procedureComplete.customerName"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                ]),
                                _c("v-row", { staticClass: "py-0 my-0" }, [
                                  _c("p", { staticClass: "py-0 my-0" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.customerName1) +
                                        "\n              "
                                    )
                                  ])
                                ]),
                                _vm.customerName2
                                  ? _c("v-row", { staticClass: "py-0 my-0" }, [
                                      _c("p", { staticClass: "py-0 my-0" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.customerName2) +
                                            "\n              "
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.customerName3
                                  ? _c("v-row", { staticClass: "py-0 my-0" }, [
                                      _c("p", { staticClass: "py-0 my-0" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.customerName3) +
                                            "\n              "
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.fixContents.length
                              ? _c("v-row", [
                                  _c("p", { staticClass: "pb-0 mb-0" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "description.procedureComplete.subTitleForModification"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _c("FixList", {
                              attrs: { fixContents: _vm.fixContents }
                            })
                          ]
                        : _vm._e(),
                      !_vm.isWithdrawl
                        ? _c(
                            "v-row",
                            [
                              _vm.isSkipped
                                ? [
                                    _c("p", { staticClass: "pt-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "description.procedureComplete.acceptFix1"
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ]),
                                    _c("p", { staticClass: "pt-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "description.procedureComplete.unconcludedContact"
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                : [
                                    _c("p", { staticClass: "pt-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "description.procedureComplete.explanation1Unconcluded"
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ]),
                                    _c("p", { staticClass: "pt-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "description.procedureComplete.explanation3Unconcluded"
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ]),
                                    _c("p", { staticClass: "pt-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "description.procedureComplete.contact"
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.isWithdrawl
                        ? _c("v-row", [
                            _c("p", { staticClass: "pt-2" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "description.procedureComplete.canceled"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]),
                            _c("p", { staticClass: "pt-2" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "description.procedureComplete.unconcludedContact"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _c("v-row", [
                _c(
                  "p",
                  {
                    staticClass: "font-weight-bold pt-5",
                    staticStyle: { "font-size": "18px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.agencyInfo")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _vm._l(_vm.agencyRecords, function(agencyRecord) {
                return _c(
                  "v-row",
                  { key: agencyRecord.key },
                  [
                    _c("v-col", { staticClass: "ma-0 pa-0" }, [
                      _c("p", { staticClass: "font-weight-bold mt-1" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "header.agencyInfoTable." + agencyRecord.key
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ]),
                    _c("v-col", { staticClass: "ma-0 pa-0" }, [
                      _c("p", { staticClass: "mt-1" }, [
                        _vm._v(_vm._s(agencyRecord.value))
                      ])
                    ])
                  ],
                  1
                )
              }),
              _vm.isConcluded
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight-black mx-auto",
                              staticStyle: { "font-size": "15px" },
                              attrs: {
                                align: "center",
                                width: "70vw",
                                "min-width": "250px",
                                "max-width": "350px",
                                color: "next",
                                dark: "",
                                rounded: ""
                              },
                              on: {
                                click: _vm.onClickDownloadApplicationFormButton
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("button.downloadDocument")))]
                          )
                        ],
                        1
                      ),
                      _c("v-row", { staticClass: "pt-3" }, [
                        _c(
                          "a",
                          {
                            staticClass: "mx-auto",
                            attrs: { href: _vm.lineRef }
                          },
                          [
                            _c("img", {
                              attrs: {
                                width: "100%",
                                src: require("@/assets/img/bnr_300_250.jpg")
                              }
                            })
                          ]
                        )
                      ]),
                      _c("SelectDownloadDocumentDialog", {
                        attrs: {
                          maxWidth: 320,
                          onClickDownloadApplicationFormButton:
                            _vm.downloadApplicationForm,
                          onClickDownloadStatementOfImportantMattersButton:
                            _vm.downloadStateOfImportantMatters,
                          onClickDonwloadInternetCovenantButton:
                            _vm.downloadInternetCovenant,
                          onClickNegativeButton:
                            _vm.onClickDownloadDialogNegativeButton,
                          showDialog: _vm.showDialog,
                          internetCovenantImage: _vm.internetCovenantImage
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }