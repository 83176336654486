<template>
  <v-content class="px-0 py-0">
    <v-container
      v-for="(fixContent, index) in fixContents"
      :key="'fixContent' + index"
      class="px-0 py-0"
    >
      <p class="font-weight-bold pl-2 pb-0 my-1">
        {{ fixContent.description }}
      </p>
      <v-row
        v-for="(fix, index) in fixContent.modificationRequests"
        :key="'fix' + index"
        class="ml-4"
      >
        <template v-if="!fixContent.clauseFlg">
          <v-col class="py-0" cols="5">
            <p class="my-1">{{ checkFix(fix) }}</p>
          </v-col>
          <v-col class="py-0" cols="7">
            <p
              v-for="(message, index) in splitMessageLine(fix.requestContent)"
              :key="index"
              class="my-1"
            >
              {{ message }}
            </p>
          </v-col>
        </template>
        <template v-else>
          <v-col class="py-0" cols="12">
            <p
              v-for="(message, index) in splitMessageLine(fix.requestContent)"
              :key="index"
              class="my-1"
            >
              {{ message }}
            </p>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  name: 'FixList',
  props: {
    fixContents: Array,
  },
  methods: {
    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    // 修正項目プラン判定
    checkFix(fix) {
      let result = fix.modificationItem
        ? fix.modificationItem.name
        : this.$t('label.other');
      if (fix.plan) {
        result = fix.plan.planName + '：' + result;
      }
      return result;
    },
  },
};
</script>
