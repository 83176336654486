<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container
        style="width: 90%; max-width: 450px; max-height=90vh;"
        class="mx-auto"
      >
        <v-row>
          <v-flex xs12>
            <v-img
              class="mx-auto"
              src="@/assets/img/logo.gif"
              width="100%"
              max-width="200px"
            ></v-img>
          </v-flex>
        </v-row>
        <!-- 締結済もしくは締結済(一部訂正要)の場合 -->
        <div v-if="isConcluded">
          <v-row>
            <p
              class="font-weight-bold mx-auto pt-5 pb-0 mb-0"
              style="font-size:18px"
            >
              {{ $t('title.contractor.noticeOfContractCompletion') }}
            </p>
            <p
              v-if="isSkipped"
              class="pt-0 mx-auto"
              style="font-size:16px"
              align="center"
            >
              {{ this.subTitle }}
            </p>
          </v-row>
          <template v-if="isSkipped">
            <v-row>
              <p class="pb-0 mb-0">
                {{
                  $t('description.procedureComplete.policyNumber', {
                    policyNumber: policyNumber,
                  })
                }}
              </p>
            </v-row>
            <v-row>
              <p>
                {{
                  $t(
                    'description.procedureComplete.differentPolicyNumberExplanation'
                  )
                }}
              </p>
            </v-row>
            <div class="pb-4">
              <v-row>
                <p class="pb-0 mb-0">
                  {{ $t('description.procedureComplete.customerName') }}
                </p>
              </v-row>
              <v-row class="py-0 my-0">
                <p class="py-0 my-0">
                  {{ customerName1 }}
                </p>
              </v-row>
              <v-row v-if="customerName2" class="py-0 my-0">
                <p class="py-0 my-0">
                  {{ customerName2 }}
                </p>
              </v-row>
              <v-row v-if="customerName3" class="py-0 my-0">
                <p class="py-0 my-0">
                  {{ customerName3 }}
                </p>
              </v-row>
            </div>
          </template>
          <div v-if="isSkipped && isConcludedWithModify">
            <v-row>
              <p class="pb-0 mb-0">
                {{
                  $t('description.procedureComplete.subTitleForModification')
                }}
              </p>
            </v-row>
            <FixList :fixContents="fixContents" />
          </div>
          <template v-if="!isSkipped && isConcluded && !isConcludedWithModify">
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation1') }}</p>
            </v-row>
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation2') }}</p>
            </v-row>
          </template>
          <template v-if="!isSkipped && isConcludedWithModify">
            <v-row>
              <p>
                {{ $t('description.procedureComplete.explanation1WithModify') }}
              </p>
            </v-row>
            <v-row>
              <p>
                {{ $t('description.procedureComplete.explanation2WithModify') }}
              </p>
            </v-row>
          </template>
          <template v-if="!isSkipped && isConcluded">
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation3') }}</p>
            </v-row>
          </template>
          <v-row>
            <p>{{ $t('description.procedureComplete.explanation4') }}</p>
          </v-row>
          <template v-if="internetCovenantImage">
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation5') }}</p>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation5-2') }}</p>
            </v-row>
          </template>
          <v-row>
            <p>
              {{ $t('description.procedureComplete.explanation6') }}<span v-if="isConcludedWithModify" class="red--text">{{ $t('description.procedureComplete.explanation6WithModifyNote') }}</span>
            </p>
          </v-row>
          <v-row>
            <p>{{ $t('description.procedureComplete.explanation7') }}</p>
          </v-row>
          <template v-if="internetCovenantImage">
            <v-row>
              <p>{{ $t('description.procedureComplete.explanation8') }}</p>
            </v-row>
          </template>
          <v-row>
            <p class="pt-2">
              {{ $t('description.procedureComplete.contact') }}
            </p>
          </v-row>
        </div>
        <!-- 非締結・取り下げの場合 -->
        <div v-if="!isConcluded">
          <v-row v-if="isWithdrawl">
            <p class="font-weight-bold mx-auto pt-5" style="font-size:18px">
              {{ $t('title.contractor.noticeOfCancel') }}
            </p>
          </v-row>
          <v-row v-if="!isWithdrawl">
            <p
              class="font-weight-bold mx-auto pt-5 pb-0 mb-0"
              style="font-size:18px"
            >
              {{ $t('title.contractor.noticeOfAcceptance') }}
            </p>
            <template v-if="isSkipped">
              <p class="pt-0 mx-auto" style="font-size:16px;" align="center">
                {{ this.subTitle }}
              </p>
            </template>
          </v-row>
          <template v-if="isSkipped">
            <v-row>
              <p>
                {{
                  $t('description.procedureComplete.policyNumber', {
                    policyNumber: policyNumber,
                  })
                }}
              </p>
            </v-row>
            <div class="pb-4">
              <v-row>
                <p class="pb-0 mb-0">
                  {{ $t('description.procedureComplete.customerName') }}
                </p>
              </v-row>
              <v-row class="py-0 my-0">
                <p class="py-0 my-0">
                  {{ customerName1 }}
                </p>
              </v-row>
              <v-row v-if="customerName2" class="py-0 my-0">
                <p class="py-0 my-0">
                  {{ customerName2 }}
                </p>
              </v-row>
              <v-row v-if="customerName3" class="py-0 my-0">
                <p class="py-0 my-0">
                  {{ customerName3 }}
                </p>
              </v-row>
            </div>
            <v-row v-if="fixContents.length">
              <p class="pb-0 mb-0">
                {{
                  $t('description.procedureComplete.subTitleForModification')
                }}
              </p>
            </v-row>
            <FixList :fixContents="fixContents" />
          </template>
          <v-row v-if="!isWithdrawl">
            <template v-if="isSkipped">
              <p class="pt-2">
                {{ $t('description.procedureComplete.acceptFix1') }}
              </p>
              <p class="pt-2">
                {{ $t('description.procedureComplete.unconcludedContact') }}
              </p>
            </template>
            <template v-else>
              <p class="pt-2">
                {{
                  $t('description.procedureComplete.explanation1Unconcluded')
                }}
              </p>
              <p class="pt-2">
                {{
                  $t('description.procedureComplete.explanation3Unconcluded')
                }}
              </p>
              <p class="pt-2">
                {{ $t('description.procedureComplete.contact') }}
              </p>
            </template>
          </v-row>
          <v-row v-if="isWithdrawl">
            <p class="pt-2">
              {{ $t('description.procedureComplete.canceled') }}
            </p>
            <p class="pt-2">
              {{ $t('description.procedureComplete.unconcludedContact') }}
            </p>
          </v-row>
        </div>
        <v-row>
          <p class="font-weight-bold pt-5" style="font-size:18px">
            {{ $t('label.agencyInfo') }}
          </p>
        </v-row>
        <v-row v-for="agencyRecord in agencyRecords" :key="agencyRecord.key">
          <v-col class="ma-0 pa-0">
            <p class="font-weight-bold mt-1">
              {{ $t(`header.agencyInfoTable.${agencyRecord.key}`) }}
            </p>
          </v-col>
          <v-col class="ma-0 pa-0">
            <p class="mt-1">{{ agencyRecord.value }}</p>
          </v-col>
        </v-row>
        <div v-if="isConcluded">
          <v-row>
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              width="70vw"
              min-width="250px"
              max-width="350px"
              color="next"
              style="font-size:15px"
              dark
              @click="onClickDownloadApplicationFormButton"
              rounded
              >{{ $t('button.downloadDocument') }}</v-btn
            >
          </v-row>
          <v-row class="pt-3">
            <a class="mx-auto" :href="lineRef">
              <img width="100%" src="@/assets/img/bnr_300_250.jpg" />
            </a>
          </v-row>
          <SelectDownloadDocumentDialog
            :maxWidth="320"
            :onClickDownloadApplicationFormButton="downloadApplicationForm"
            :onClickDownloadStatementOfImportantMattersButton="
              downloadStateOfImportantMatters
            "
            :onClickDonwloadInternetCovenantButton="downloadInternetCovenant"
            :onClickNegativeButton="onClickDownloadDialogNegativeButton"
            :showDialog="showDialog"
            :internetCovenantImage="internetCovenantImage"
          />
        </div>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import SelectDownloadDocumentDialog from '@/components/organisms/contractor/SelectDownloadDocumentDialog.vue';
import {
  getAgencyInfo,
  getCompletedApplicationForm,
  getExplanationsForImportantMatter,
  getInternetCovenant,
  getStatus,
  getContractDate,
  getModificationRequests,
  getBaseInfo,
} from '@/apis/contractor/contracts';
import { ContractStatus } from '@/lib/const';
import { mapActions, mapGetters } from 'vuex';
import { LineID } from '@/lib/const';
import FixList from '@/components/molecules/contractor/FixList.vue';

export default {
  components: {
    SelectDownloadDocumentDialog,
    FixList,
  },
  data: () => ({
    isConcluded: false,
    isConcludedWithModify: false,
    isWithdrawl: false,
    agencyRecords: [
      {
        key: 'agencyName',
        value: '',
      },
      {
        key: 'chargePersonName',
        value: '',
      },
      {
        key: 'agencyPhoneNumber',
        value: '',
      },
    ],
    lineRef: `https://line.me/R/ti/p/@${LineID}`,
    subTitle: '',
    fixContents: [],
    showDialog: false,
    policyNumber: '',
    customerName1: '',
    customerName2: null,
    customerName3: null,
    internetCovenantImage: false,

    // スキップされたかどうか
    isSkipped: false,
  }),
  methods: {
    onClickDownloadApplicationFormButton() {
      this.showDialog = true;
    },
    async downloadApplicationForm() {
      const applicationForms = await getCompletedApplicationForm();
      if (applicationForms instanceof Error) {
        this.$router.replace('/Error');
        return;
      }
      this.download(applicationForms, this.$t('file.applicationForm'));
    },
    async downloadStateOfImportantMatters() {
      const explanationsForImportantMatter = await getExplanationsForImportantMatter();
      if (explanationsForImportantMatter instanceof Error) {
        this.$router.replace('/Error');
        return;
      }
      this.download(
        explanationsForImportantMatter,
        this.$t('file.explanationsForImportantMatter')
      );
    },
    async downloadInternetCovenant() {
      const internetCovenant = await getInternetCovenant();
      if (internetCovenant instanceof Error) {
        this.$router.replace('/Error');
        return;
      }
      this.download(internetCovenant, this.$t('file.internetCovenant'));
    },
    download(data, fileName) {
      if (data) {
        let link = document.createElement('a');

        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera;
        let isIos = false;
        // iOS機種であるかを判別
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          isIos = true;
        }
        if (isIos) {
          link.href = window.URL.createObjectURL(
            new Blob([data], { type: 'application/octet-stream' })
          );
        } else {
          link.href = window.URL.createObjectURL(
            new Blob([data], { type: 'application/pdf' })
          );
        }

        link.download = fileName;
        link.click();
      }
    },
    onClickDownloadDialogNegativeButton() {
      this.showDialog = false;
    },
    ...mapGetters('contract', ['fix', 'isProcedureSkipped']),
    ...mapActions('contract', ['setFix']),
  },
  async mounted() {
    // 手続きがスキップ指定である(再ログイン時など)かどうかをセットする
    this.$set(this, 'isSkipped', this.isProcedureSkipped());

    const contractStatus = await getStatus();
    this.$set(
      this,
      'isConcluded',
      contractStatus.status === ContractStatus.Concluded ||
        contractStatus.status === ContractStatus.ConcludedWithModify ||
        contractStatus.status === ContractStatus.Closed
    );

    if (contractStatus.status != ContractStatus.Concluded) {
      this.fixContents = await getModificationRequests();
    }

    this.isConcludedWithModify = this.fixContents.length > 0;

    this.isWithdrawl =
      contractStatus.status === ContractStatus.Withdrawal ||
      contractStatus.status === ContractStatus.Deleted;

    const response = await getAgencyInfo();
    if (response instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (response) {
      this.$set(this, 'agencyRecords', [
        {
          key: 'agencyName',
          value: response.agencyName,
        },
        {
          key: 'chargePersonName',
          value: response.chargePersonName,
        },
        {
          key: 'agencyPhoneNumber',
          value: response.agencyPhoneNumber,
        },
      ]);
    }
    const contractDateResponse = await getContractDate();
    if (contractDateResponse instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (contractDateResponse) {
      const contractDate = moment(contractDateResponse.contractDate).format(
        'YYYY年M月D日'
      );
      switch (contractStatus.status) {
        case ContractStatus.Concluded:
          this.subTitle = this.$t('description.procedureComplete.subTitle', {
            date: contractDate,
          });
          break;
        case ContractStatus.ConcludedWithModify:
          this.subTitle = this.$t(
            'description.procedureComplete.subTitleWithModify',
            {
              date: contractDate,
            }
          );
          break;
        case ContractStatus.Closed:
          this.subTitle = this.$t(
            this.isConcludedWithModify
              ? 'description.procedureComplete.subTitleWithModify'
              : 'description.procedureComplete.subTitle',
            {
              date: contractDate,
            }
          );
          break;
        case ContractStatus.Unconcluded:
          this.subTitle = this.$t(
            'description.procedureComplete.subTitleNotConcluded',
            {
              date: contractDate,
            }
          );
          break;
      }
    }
    const baseInfoResponse = await getBaseInfo();
    if (baseInfoResponse instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (baseInfoResponse) {
      this.policyNumber = baseInfoResponse.policyNumber;
      if (baseInfoResponse.fullNameKanji3.length > 0) {
        this.customerName3 = this.$t(
          'description.procedureComplete.titleOfHonor',
          { name: baseInfoResponse.fullNameKanji3 }
        );
        this.customerName2 = baseInfoResponse.fullNameKanji2;
        this.customerName1 = baseInfoResponse.fullNameKanji1;
      } else if (baseInfoResponse.fullNameKanji2.length > 0) {
        this.customerName2 = this.$t(
          'description.procedureComplete.titleOfHonor',
          { name: baseInfoResponse.fullNameKanji2 }
        );
        this.customerName1 = baseInfoResponse.fullNameKanji1;
      } else if (baseInfoResponse.fullNameKanji1.length > 0) {
        this.customerName1 = this.$t(
          'description.procedureComplete.titleOfHonor',
          { name: baseInfoResponse.fullNameKanji1 }
        );
      }
      this.internetCovenantImage = baseInfoResponse.internetCovenantImage;
    }
  },
};
</script>
