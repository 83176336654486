var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    { staticClass: "px-0 py-0" },
    _vm._l(_vm.fixContents, function(fixContent, index) {
      return _c(
        "v-container",
        { key: "fixContent" + index, staticClass: "px-0 py-0" },
        [
          _c("p", { staticClass: "font-weight-bold pl-2 pb-0 my-1" }, [
            _vm._v("\n      " + _vm._s(fixContent.description) + "\n    ")
          ]),
          _vm._l(fixContent.modificationRequests, function(fix, index) {
            return _c(
              "v-row",
              { key: "fix" + index, staticClass: "ml-4" },
              [
                !fixContent.clauseFlg
                  ? [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "5" } },
                        [
                          _c("p", { staticClass: "my-1" }, [
                            _vm._v(_vm._s(_vm.checkFix(fix)))
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "7" } },
                        _vm._l(
                          _vm.splitMessageLine(fix.requestContent),
                          function(message, index) {
                            return _c(
                              "p",
                              { key: index, staticClass: "my-1" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(message) +
                                    "\n          "
                                )
                              ]
                            )
                          }
                        ),
                        0
                      )
                    ]
                  : [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        _vm._l(
                          _vm.splitMessageLine(fix.requestContent),
                          function(message, index) {
                            return _c(
                              "p",
                              { key: index, staticClass: "my-1" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(message) +
                                    "\n          "
                                )
                              ]
                            )
                          }
                        ),
                        0
                      )
                    ]
              ],
              2
            )
          })
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }